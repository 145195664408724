footer {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: $black;
  .logo {
    width: 300px;
    @include media-breakpoint-down(sm) {
      width: 230px;
    }
  }
  .row:first-of-type {
    margin-bottom: 50px;
  }
  p, a {
    color: $white;
  }
  a:hover {
    color: $white;
    text-decoration: underline;
  }
  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }
}
