.btn {
  margin-top: 3.125rem;
  margin-bottom: 1rem;
  position: relative;
  text-transform: uppercase;
  &:before {
    content: "";
    position: absolute;
    width: 15px;
    height: 1px;
    background-color: $white;
    left: 20px;
    top: -1px;
  }
  &:after {
    content: "";
    position: absolute;
    width: 15px;
    height: 1px;
    background-color: $white;
    right: 20px;
    bottom: -1px;
  }
}
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle, .btn:hover {
  &:before {
    background-color: $black;
  }
  &:after {
    background-color: $black;
  }
}
