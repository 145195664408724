.hamburger-button {
  cursor: pointer;
  width: 33px;
  height: 32px;
  position: relative;
  transition: all 300ms ease;
  display: none;
  border: 1px solid $black;
  &:before {
    position: absolute;
    display: block;
    width: 5px;
    height: 1px;
    background-color: $white;
    left: 3px;
    top: -1px;
    content: "";
  }
  &:after {
    position: absolute;
    display: block;
    width: 5px;
    height: 1px;
    background-color: $white;
    right: 3px;
    bottom: -1px;
    content: "";
  }
  @include media-breakpoint-down(md) {
    top: 21px;
    display: inline-block;
  }
  @include media-breakpoint-down(sm) {
    top: 13px;
  }
  span {
    position: absolute;
    width: 18px;
    height: 1px;
    background-color: $black;
    display: block;
    left: 6px;
    transition: all 300ms ease;
    opacity: 1;
  }
  span:nth-of-type(1) {
    top: 6px
  }
  span:nth-of-type(2) {
    top: 14px
  }
  span:nth-of-type(3) {
    top: 22px
  }

  &.open {
    span:nth-of-type(1) {
      top: 14px;
      transform: rotate(45deg);
    }
    span:nth-of-type(2) {
      left: -6px;
      opacity: 0;
    }
    span:nth-of-type(3) {
      top: 14px;
      transform: rotate(-45deg);
    }
  }
}
