nav.head {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  transition: all 0.5s ease;
  width: 100%;
  z-index: 11;
  box-shadow: -1px 1px 12px 7px rgba(0,0,0,0.11);
  .wrapper {
    display: flex;
    flex-direction:row;
    @include media-breakpoint-down(md) {
      justify-content: space-between;
    }
  }
  .logo {
    width: 200px;
    @include media-breakpoint-down(sm) {
      width: 150px;
    }
    a {
      display: block;
      padding: 20px 0;
      margin-right: 20px;
      @include media-breakpoint-down(sm) {
        padding: 15px 0;
      }
    }
  }
  .menu {
    @include media-breakpoint-down(md) {
      display: none;
    }
    flex-grow: 1;
    ul {
      margin-bottom: 0;
      padding-left: 0;
      display: flex;
      list-style: none;
      flex-flow: row nowrap;
      flex-direction:row;
      justify-content: space-between;
      li {
        width: 100%;
        text-align: center;
        a {
          width: 100%;
          transition: all 250ms ease;
          background-color: $white;
          padding: 30px 20px;
          color: $black;
          display: block;
          text-decoration: none;
          &:hover {
            background-color: $black;
            color: $white;
          }
        }
      }
    }
  }
  &.open {
    background-color: $black;
    .hamburger-button {
      border: 1px solid $white;
      &:before {
        background-color: $black;
      }
      &:after {
        background-color: $black;
      }
      span {
        background-color: $white;
      }
    }
  }
}

nav.mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: $primary;
  transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate(-100%);
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  padding-top: 90px;
  @include media-breakpoint-down(sm) {
    padding-top: 63.56px;
  }
  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }
  &.open {
    transform: translate(0%);
  }
  ul {
    padding: 0;
    list-style: none;
    display: block;
    li {
      margin-left: 0;
      padding: 0;
      margin-bottom: 25px;
      &:before {
        display: none;
      }
      a {
        display: block;
        color: $white;
        position: relative;
        &:hover, &:active {
          color: $white;
          text-decoration: underline;
        }
      }
    }
  }
  .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mobile-nav-bounce-fixer {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  background-color: $primary;
  display: none;
  &.open {
    display: block;
  }
}
