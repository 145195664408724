section {
  margin-bottom: 100px;
  &.header {
    margin-top: 100px;
  }
  .info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 100%;
    p {
      text-transform: uppercase;
      color: $white;
      letter-spacing: 4.1px;
      opacity: 0.9;
    }
  }
}
