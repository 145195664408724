*:focus {
  outline:none !important;
}
.hidden {
  display: none;
}

img {
  width: 100%;
}
h1, .display-1, .display-2 {
  letter-spacing: 12px;
}
.display-1, .display-2 {
  display: block;
  text-transform: uppercase;
  line-height: 74px;
  color: $white;
  @include media-breakpoint-down(sm) {
    font-size: 30px;
    line-height: 41px;
    letter-spacing: 6.67px;
  }
}
h1 {
  font-weight: 700;
  @include media-breakpoint-down(sm) {
    font-size: 30px;
    letter-spacing: 5px;
  }
}
h3 {
  font-weight: 400;
}


h1, h2 {
  text-transform: uppercase;
}
h2 {
  letter-spacing: 5px;
  @include media-breakpoint-down(sm) {
    font-size: 25px;
    letter-spacing: 4.17px;
  }
}

h3, h4 {
  letter-spacing: 3px;
  @include media-breakpoint-down(sm) {
    font-size: 20px;
    letter-spacing: 2px;
  }
}

h5, h6 {
  letter-spacing: 1px;
  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}
